<template>
  <div class="ma-5">
    <v-toolbar>
      <v-toolbar-title>Cov-Cares Stoplight Survey</v-toolbar-title>
      <v-spacer></v-spacer>
      <span style="min-width:100px;text-align:right">{{ termLabel }}</span>
    </v-toolbar>
    <v-alert v-if="loading" type="info">Loading courses...</v-alert>
    <v-alert v-else-if="courses.length === 0" type="error">No courses found</v-alert>
    <v-row v-else class="mt-2">
      <v-col :cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <v-card>
          <v-list>
            <v-list-item v-for="{ _id, title, studentCount, surveyDate } in courses" :key="_id" :to="'/cov-cares/stoplight/' + _id" class="course-item">
              <v-list-item-content>
                <v-list-item-title>{{ title  }}</v-list-item-title>
                <v-list-item-subtitle>Students: {{  studentCount }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="surveyDate">Survey completed: {{ stringFormatDate(surveyDate) }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>No Stoplight Survey Submitted</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="surveyDate ? 'success' : 'info'">fa fa-{{ surveyDate ? 'check' : 'exclamation' }}-circle</v-icon>
                  </template>
                  <span v-if="surveyDate">Survey completed on {{ stringFormatDate(surveyDate) }}</span>
                  <span v-else>No Stoplight Survey Submitted</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.v-list-item.course-item:not(:last-child) {
  border-bottom:1px solid #EEE
}
.v-list-item.course-item.theme--dark:not(:last-child) {
  border-bottom:1px solid #333
}
</style>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const term = ref('')
    const termLabel = ref('')
    const courses = ref([])
    const loading = ref(true)

    onMounted(async () => {
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', 'stoplight.open': { $lte: new Date() }, 'stoplight.close': { $gte: new Date() } } })
      if (termData.length > 0) {
        term.value = termData[0].term
        termLabel.value = termData[0].label
      } else {
        alert('Not currently in a traditional term. Stoplight surveys are currently only available for the traditional terms.')
        return
      }
      root.$store.dispatch('cov-cares/loadStoplightConcernOptions')

      const { data } = await root.$feathers.service('calendar/classes').find({ query: { term: term.value, 'instructors.pidm': user.value.pidm, $select: ['_id', 'term', 'crn', 'title', 'counts'] } })
      const crns = []
      const arr = data.map(({ _id, term, crn, title, counts: { reg } }) => {
        crns.push(crn)
        return {
          _id,
          term,
          crn,
          title,
          surveyDate: null,
          studentCount: reg
        }
      })
      const { data: stoplightData } = await root.$feathers.service('cov-cares/stoplight').find({ query: { term: term.value, crn: { $in: crns } } })
      for (const { crn, createdAt, updatedAt } of stoplightData) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].crn === crn) {
            arr[i].surveyDate = new Date(updatedAt || createdAt)
            break
          }
        }
      }
      courses.value = arr
      loading.value = false
    })

    return {
      user,
      term,
      termLabel,
      courses,
      loading,
      stringFormatDate
    }
  }
}
</script>
